/**
* Swiper 3.4.2
* Most modern mobile touch slider and framework with hardware accelerated transitions
*
* http://www.idangero.us/swiper/
*
* Copyright 2017, Vladimir Kharlampidi
* The iDangero.us
* http://www.idangero.us/
*
* Licensed under MIT
*
* Released on: March 10, 2017
*/
.swiper-container {
	background: #333333;
	height: 300px;
	margin: 0 0 120px;
	overflow: hidden;
	position: relative;
	position: relative;
	/* Fix of Webkit flickering */
	z-index: 1;
}

@media (min-width: 768px) {
	.swiper-container {
		height: 450px;
	}
}

@media (min-width: 992px) {
	.swiper-container {
		height: 600px;
	}

	.home .swiper-container {
		height: 544px;
	}
}

.swiper-container-no-flexbox .swiper-slide {
	float: left;
}
.swiper-container-vertical > .swiper-wrapper {
	-ms-flex-direction: column;
	    flex-direction: column;

	-webkit-box-orient: vertical;
}
.swiper-wrapper {
	box-sizing: content-box;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	height: 100%;
	position: relative;
	-webkit-transition-property: -webkit-transform;
	        transition-property: -webkit-transform;
	        transition-property:         transform;
	        transition-property:         transform, -webkit-transform;
	width: 100%;
	z-index: 1;
}
.swiper-container-android .swiper-slide,
.swiper-wrapper {
	-webkit-transform: translate3d(0px, 0, 0);
	        transform: translate3d(0px, 0, 0);
}
.swiper-container-multirow > .swiper-wrapper {
	-webkit-box-lines: multiple;
	   -moz-box-lines: multiple;
	-ms-flex-wrap: wrap;
	    flex-wrap: wrap;
}
.swiper-container-free-mode > .swiper-wrapper {
	margin: 0 auto;
	-webkit-transition-timing-function: ease-out;
	        transition-timing-function: ease-out;
}
.swiper-slide {
	height: 100%;
	position: relative;
	width: 100%;

	-webkit-flex-shrink: 0;
	-ms-flex: 0 0 auto;
	-ms-flex-negative: 0;
	        flex-shrink: 0;
}

.swiper-slide .image {
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
}

.slider-content {
	background: rgba(255,255,255,0.9);
	left: 50%;
	max-width: 500px;
	padding: 30px;
	position: absolute;
	text-align: center;
	top: 50%;
	-webkit-transform: translate(-50%,-50%);
	        transform: translate(-50%,-50%);
	z-index: 200;
}

.slider-content img {
	height: auto;
	margin: 0 0 15px;
	max-width: 270px !important;
	width: 100%;
}

.slider-content strong {
	display: block;
	font-size: 30px !important;
	font-weight: 600 !important;
	line-height: 38px !important;
	margin: 0 0 15px !important;
}

.slider-content p {
	font-size: 20px !important;
	line-height: 28px !important;
}

.slider-content a {
	font-weight: 600;
}

.slider-content a::before {
	margin: 0 10px 0 0;
}

/* Auto Height */
.swiper-container-autoheight,
.swiper-container-autoheight .swiper-slide {
	height: auto;
}
.swiper-container-autoheight .swiper-wrapper {
	-ms-flex-align: start;
	-webkit-transition-property: -webkit-transform, height;
	-webkit-transition-property: height, -webkit-transform;
	        transition-property: height, -webkit-transform;
	        transition-property:         transform, height;
	        transition-property:         transform, height, -webkit-transform;

	-webkit-box-align: start;
	align-items: flex-start;
}
/* a11y */
.swiper-container .swiper-notification {
	left: 0;
	opacity: 0;
	position: absolute;
	top: 0;
	z-index: -1000;

	pointer-events: none;
}
/* IE10 Windows Phone 8 Fixes */
.swiper-wp8-horizontal {
	-ms-touch-action: pan-y;
	    touch-action: pan-y;
}
.swiper-wp8-vertical {
	-ms-touch-action: pan-x;
	    touch-action: pan-x;
}
/* Arrows */
.swiper-button-prev,
.swiper-button-next {
	color: #009380;
	cursor: pointer;
	font-size: 48px;
	height: 44px;
	line-height: 44px;
	margin-top: -22px;
	position: absolute;
	text-align: center;
	top: 50%;
	width: 44px;
	z-index: 10;
}
.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
	cursor: auto;
	opacity: 0.35;

	pointer-events: none;
}
.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
	left: 30px;
	right: auto;
}
.swiper-button-prev.swiper-button-black,
.swiper-container-rtl .swiper-button-next.swiper-button-black {
	background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
}
.swiper-button-prev.swiper-button-white,
.swiper-container-rtl .swiper-button-next.swiper-button-white {
	background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
}
.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
	left: auto;
	right: 30px;
}
.swiper-button-next.swiper-button-black,
.swiper-container-rtl .swiper-button-prev.swiper-button-black {
	background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
}
.swiper-button-next.swiper-button-white,
.swiper-container-rtl .swiper-button-prev.swiper-button-white {
	background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
}
/* Pagination Styles */
.swiper-pagination {
	position: absolute;
	text-align: center;
	-webkit-transform: translate3d(0, 0, 0);
	        transform: translate3d(0, 0, 0);
	-webkit-transition: 300ms;
	        transition: 300ms;
	z-index: 10;
}
.swiper-pagination.swiper-pagination-hidden {
	opacity: 0;
}
/* Common Styles */
.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
	bottom: 30px;
	left: 0;
	width: 100%;
}
/* Bullets */
.swiper-pagination-bullet {
	background: #000000;
	border-radius: 100%;
	display: inline-block;
	height: 10px;
	opacity: 0.2;
	-webkit-transition: all 0.3s ease 0s;
	        transition: all 0.3s ease 0s;
	width: 10px;
}
button.swiper-pagination-bullet {
	border: none;
	box-shadow: none;
	margin: 0;
	padding: 0;

	   -moz-appearance: none;
	    -ms-appearance: none;
	-webkit-appearance: none;
	        appearance: none;
}
.swiper-pagination-clickable .swiper-pagination-bullet {
	cursor: pointer;
}
.swiper-pagination-white .swiper-pagination-bullet {
	background: #ffffff;
}
.swiper-pagination-bullet-active {
	background: #009380;
	opacity: 1;
}
.swiper-pagination-white .swiper-pagination-bullet-active {
	background: #ffffff;
}
.swiper-pagination-black .swiper-pagination-bullet-active {
	background: #000000;
}
.swiper-container-vertical > .swiper-pagination-bullets {
	right: 10px;
	top: 50%;
	-webkit-transform: translate3d(0px, -50%, 0);
	        transform: translate3d(0px, -50%, 0);
}
.swiper-container-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
	display: block;
	margin: 5px 0;
}
.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
	margin: 0 5px;
}
/* Progress */
.swiper-pagination-progress {
	background: rgba(0, 0, 0, 0.25);
	position: absolute;
}
.swiper-pagination-progress .swiper-pagination-progressbar {
	background: #007aff;
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	-webkit-transform: scale(0);
	        transform: scale(0);
	-webkit-transform-origin: left top;
	        transform-origin: left top;
	width: 100%;
}
.swiper-container-rtl .swiper-pagination-progress .swiper-pagination-progressbar {
	-webkit-transform-origin: right top;
	        transform-origin: right top;
}
.swiper-container-horizontal > .swiper-pagination-progress {
	height: 4px;
	left: 0;
	top: 0;
	width: 100%;
}
.swiper-container-vertical > .swiper-pagination-progress {
	height: 100%;
	left: 0;
	top: 0;
	width: 4px;
}
.swiper-pagination-progress.swiper-pagination-white {
	background: rgba(255, 255, 255, 0.5);
}
.swiper-pagination-progress.swiper-pagination-white .swiper-pagination-progressbar {
	background: #ffffff;
}
.swiper-pagination-progress.swiper-pagination-black .swiper-pagination-progressbar {
	background: #000000;
}
/* 3D Container */
.swiper-container-3d {
	-webkit-perspective: 1200px;
	     -o-perspective: 1200px;
	        perspective: 1200px;
}
.swiper-container-3d .swiper-wrapper,
.swiper-container-3d .swiper-slide,
.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top,
.swiper-container-3d .swiper-slide-shadow-bottom,
.swiper-container-3d .swiper-cube-shadow {
	-webkit-transform-style: preserve-3d;
	        transform-style: preserve-3d;
}
.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top,
.swiper-container-3d .swiper-slide-shadow-bottom {
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 10;

	pointer-events: none;
}
.swiper-container-3d .swiper-slide-shadow-left {
	background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
	/* Safari 4+, Chrome */
	background-image: -webkit-linear-gradient(right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
	/* Chrome 10+, Safari 5.1+, iOS 5+ */
	/* Firefox 3.6-15 */
	/* Opera 11.10-12.00 */
	background-image:         linear-gradient(to left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
	/* Firefox 16+, IE10, Opera 12.50+ */
}
.swiper-container-3d .swiper-slide-shadow-right {
	background-image: -webkit-gradient(linear, right top, left top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
	/* Safari 4+, Chrome */
	background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
	/* Chrome 10+, Safari 5.1+, iOS 5+ */
	/* Firefox 3.6-15 */
	/* Opera 11.10-12.00 */
	background-image:         linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
	/* Firefox 16+, IE10, Opera 12.50+ */
}
.swiper-container-3d .swiper-slide-shadow-top {
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
	/* Safari 4+, Chrome */
	background-image: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
	/* Chrome 10+, Safari 5.1+, iOS 5+ */
	/* Firefox 3.6-15 */
	/* Opera 11.10-12.00 */
	background-image:         linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
	/* Firefox 16+, IE10, Opera 12.50+ */
}
.swiper-container-3d .swiper-slide-shadow-bottom {
	background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
	/* Safari 4+, Chrome */
	background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
	/* Chrome 10+, Safari 5.1+, iOS 5+ */
	/* Firefox 3.6-15 */
	/* Opera 11.10-12.00 */
	background-image:         linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
	/* Firefox 16+, IE10, Opera 12.50+ */
}
/* Coverflow */
.swiper-container-coverflow .swiper-wrapper,
.swiper-container-flip .swiper-wrapper {
	/* Windows 8 IE 10 fix */
	-ms-perspective: 1200px;
}
/* Cube + Flip */
.swiper-container-cube,
.swiper-container-flip {
	overflow: visible;
}
.swiper-container-cube .swiper-slide,
.swiper-container-flip .swiper-slide {
	z-index: 1;

	pointer-events: none;
	-webkit-backface-visibility: hidden;
	        backface-visibility: hidden;
}
.swiper-container-cube .swiper-slide .swiper-slide,
.swiper-container-flip .swiper-slide .swiper-slide {
	pointer-events: none;
}
.swiper-container-cube .swiper-slide-active,
.swiper-container-flip .swiper-slide-active,
.swiper-container-cube .swiper-slide-active .swiper-slide-active,
.swiper-container-flip .swiper-slide-active .swiper-slide-active {
	pointer-events: auto;
}
.swiper-container-cube .swiper-slide-shadow-top,
.swiper-container-flip .swiper-slide-shadow-top,
.swiper-container-cube .swiper-slide-shadow-bottom,
.swiper-container-flip .swiper-slide-shadow-bottom,
.swiper-container-cube .swiper-slide-shadow-left,
.swiper-container-flip .swiper-slide-shadow-left,
.swiper-container-cube .swiper-slide-shadow-right,
.swiper-container-flip .swiper-slide-shadow-right {
	z-index: 0;

	-webkit-backface-visibility: hidden;
	        backface-visibility: hidden;
}
/* Cube */
.swiper-container-cube .swiper-slide {
	height: 100%;
	-webkit-transform-origin: 0 0;
	        transform-origin: 0 0;
	visibility: hidden;
	width: 100%;
}
.swiper-container-cube.swiper-container-rtl .swiper-slide {
	-webkit-transform-origin: 100% 0;
	        transform-origin: 100% 0;
}
.swiper-container-cube .swiper-slide-active,
.swiper-container-cube .swiper-slide-next,
.swiper-container-cube .swiper-slide-prev,
.swiper-container-cube .swiper-slide-next + .swiper-slide {
	visibility: visible;

	pointer-events: auto;
}
.swiper-container-cube .swiper-cube-shadow {
	background: #000000;
	bottom: 0;
	-webkit-filter: blur(50px);
	        filter: blur(50px);
	height: 100%;
	left: 0;
	opacity: 0.6;
	position: absolute;
	width: 100%;
	z-index: 0;
}
/* Fade */
.swiper-container-fade.swiper-container-free-mode .swiper-slide {
	-webkit-transition-timing-function: ease-out;
	        transition-timing-function: ease-out;
}
.swiper-container-fade .swiper-slide {
	-webkit-transition-property: opacity;
	        transition-property: opacity;

	pointer-events: none;
}
.swiper-container-fade .swiper-slide .swiper-slide {
	pointer-events: none;
}
.swiper-container-fade .swiper-slide-active,
.swiper-container-fade .swiper-slide-active .swiper-slide-active {
	pointer-events: auto;
}
.swiper-zoom-container {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	-ms-flex-pack: center;
	height: 100%;
	text-align: center;
	width: 100%;

	-webkit-box-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	align-items: center;
}
.swiper-zoom-container > img,
.swiper-zoom-container > svg,
.swiper-zoom-container > canvas {
	max-height: 100%;
	max-width: 100%;

	-o-object-fit: contain;
	   object-fit: contain;
}
/* Scrollbar */
.swiper-scrollbar {
	background: rgba(0, 0, 0, 0.1);
	border-radius: 10px;
	position: relative;

	-ms-touch-action: none;
}
.swiper-container-horizontal > .swiper-scrollbar {
	bottom: 3px;
	height: 5px;
	left: 1%;
	position: absolute;
	width: 98%;
	z-index: 50;
}
.swiper-container-vertical > .swiper-scrollbar {
	height: 98%;
	position: absolute;
	right: 3px;
	top: 1%;
	width: 5px;
	z-index: 50;
}
.swiper-scrollbar-drag {
	background: rgba(0, 0, 0, 0.5);
	border-radius: 10px;
	height: 100%;
	left: 0;
	position: relative;
	top: 0;
	width: 100%;
}
.swiper-scrollbar-cursor-drag {
	cursor: move;
}
/* Preloader */
.swiper-lazy-preloader {
	-webkit-animation: swiper-preloader-spin 1s steps(12, end) infinite;
	        animation: swiper-preloader-spin 1s steps(12, end) infinite;
	height: 42px;
	left: 50%;
	margin-left: -21px;
	margin-top: -21px;
	position: absolute;
	top: 50%;
	-webkit-transform-origin: 50%;
	        transform-origin: 50%;
	width: 42px;
	z-index: 10;
}
.swiper-lazy-preloader:after {
	background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%236c6c6c'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
	background-position: 50%;
	background-repeat: no-repeat;
	        background-size: 100%;
	content: "";
	display: block;
	height: 100%;
	width: 100%;

	-webkit-background-size: 100%;
}
.swiper-lazy-preloader-white:after {
	background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%23fff'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
}
@-webkit-keyframes swiper-preloader-spin {
	100% {
		-webkit-transform: rotate(360deg);
	}
}
@keyframes swiper-preloader-spin {
	100% {
		-webkit-transform: rotate(360deg);
		        transform: rotate(360deg);
	}
}
